<template>
	<section>
		<div class="page-content">
			<div class="banner">
				<video autoplay="autoplay" :muted="muted_status" loop="loop" src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/xcx991.mp4">
					your browser does not support the video tag
				</video>
				<div class="section">
					<div class="section-content banner-con">
						<div class="banner-con-con">
							<div class="banner-c-title">小程序公众号·一触即达</div>
							<div class="banner-c-text">搭建多方位营销矩阵为企业搭建一站式营销中心，可轻松对接多个营销终端。</div>
						</div>
						<div v-if="muted_status" class="music music-off" @click="muted_status=false"><i class="iconfont icon-music_off_px_rounded"></i></div>
						<div v-else class="music music-on" @click="muted_status=true"><i class="iconfont icon-music_note_px_rounded"></i></div>
					</div>
				</div>

			</div>
			<div class="introduce-box">
				
				
				<div class="section">
					<div class="section-content">
						<div class="introduce">
							<div class="introduce-item">
								<p class="introduce-title">搭建多方位营销矩阵</p>
								<div class="introduce-text">
									<p>为企业搭建一站式营销中心，可轻松</p>
									<p>对接多个营销终端。</p>
								</div>
							</div>
							<div class="introduce-item">
								<p class="introduce-title">持续开发创新营销产品</p>
								<div class="introduce-text">
									<p>紧跟市场新创营销方式，研发客户真</p>
									<p>正需要的营销产品。</p>
								</div>
							</div>
							<div class="introduce-item">
								<p class="introduce-title">定制专属营销策略</p>
								<div class="introduce-text">
									<p>为客户量身定制营销方案，实现客户</p>
									<p>所预期的营销功能。</p>
								</div>
							</div>
							<div class="introduce-item">
								<p class="introduce-title">技术支持服务</p>
								<div class="introduce-text">
									<p>提供终身免费技术支持服务，解决客</p>
									<p>户软件使用烦恼，轻松上云。</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="section">
				<div class="section-content">
					<!-- 产品服务 -->
					<div class="part1">
						<div class="part">
							<div class="title-module">
								<div class="main-title">我们的产品</div>
								<div class="sub-title">
									<p>为企业搭建一站式营销中心，可轻松</p>
									<p>对接多个营销终端。</p>
								</div>
							</div>

							<div class="part1-con">
								<div class="p1-con-item">
									<i class="p1-con-icon iconfont icon-yingxiaoguanli"></i>
									<p class="p1-con-title">营销矩阵</p>
									<div class="p1-con-con">
										<p>为企业搭建一站式营销中心，可轻松<p/>
										<p>对接多个营销小程序</p>
									</div>	
								</div>
								<div class="p1-con-item">
									<i class="p1-con-icon iconfont icon-danwei"></i>
									<p class="p1-con-title">海量模版</p>
									<div class="p1-con-con">
										<p>海量行业模板，随意挑选，随时更改</p>
									</div>	
								</div>
								<div class="p1-con-item">
									<i class="p1-con-icon iconfont icon-shebeijieruIoT"></i>
									<p class="p1-con-title">极速接入</p>
									<div class="p1-con-con">
										<p>极速建站，只需几步就可拥有专属小程序</p>
									</div>	
								</div>
								<div class="p1-con-item">
									<i class="p1-con-icon iconfont icon-gongnengdingyi"></i>
									<p class="p1-con-title">功能丰富</p>
									<div class="p1-con-con">
										<p>享有各类功能，性价比高，产品稳定</p>
										<p>每周持续更新</p>
									</div>	
								</div>
								<div class="p1-con-item">
									<i class="p1-con-icon iconfont icon-houtaiweihu"></i>
									<p class="p1-con-title">维护便利</p>
									<div class="p1-con-con">
										<p>维护网站便利，自己可即时修改网站内容</p>
										<p>7*24安全监控</p>
									</div>	
								</div>
								<div class="p1-con-item">
									<i class="p1-con-icon iconfont icon-fuwu"></i>
									<p class="p1-con-title">优质服务</p>
									<div class="p1-con-con">
										<p>易用的平台，流畅的操作，坚实的技术提供</p>
										<p>零技术的轻松建站享受</p>
									</div>	
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- 在线商城 -->
			<div class="online">
				<div class="section">
					<div class="section-content online-con">
						<div class="online-left">
							<div class="online-title">云商城</div>
							<div class="online-pro">搭建多方位营销矩阵为企业搭建一站式营销中心，可轻松对接多个营销终端。</div>
							<div class="online-price">标准版 ￥ 2980.00/年</div>
							<div class="online-button" @click="product_detail('1')">查看详情 →</div>
						</div>
						<div class="online-right">
							
							<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/online_bg.png">
						</div>
					</div>
				</div>
				
			</div>


			<div class="section">
				<div class="section-content">
					
					<!-- 丰富模版 -->
					<div class="part-moudel">
						<div class="part">
							<div class="title-module">
								<div class="main-title">丰富模版</div>
								<div class="sub-title">
									<p>为企业搭建一站式营销中心，可轻松</p>
									<p>对接多个营销终端。</p>
								</div>
							</div>
							<div class="part-moudel-con">
								<ul class="p-moudel-tab">
									<li :class="{active:moudelName==1}" @click="moudelf(1)">服装</li>/
									<li :class="{active:moudelName==2}" @click="moudelf(2)">食品</li>/
									<li :class="{active:moudelName==3}" @click="moudelf(3)">家具</li>/
									<li :class="{active:moudelName==4}" @click="moudelf(4)">礼品</li>/
									<li :class="{active:moudelName==5}" @click="moudelf(5)">酒店</li>/
									<li :class="{active:moudelName==6}" @click="moudelf(6)">餐饮</li>/
									<li :class="{active:moudelName==7}" @click="moudelf(7)">汽车</li>/
									<li :class="{active:moudelName==8}" @click="moudelf(8)">设备</li>

								</ul>
								 <!-- v-if="moudelName==1" -->
								<div class="p-moudel-con">
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a1.png">
									</div>
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a2.png">
									</div>
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a3.png">
									</div>
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a4.png">
									</div>
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a5.png">
									</div>
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a6.png">
									</div>
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a7.png">
									</div>
									<div class="p-moudel-con-item">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_moudel/a8.png">
									</div>


								</div>
							</div>
						</div>
					</div>
					

					
				</div>
			</div>
			<!-- 注册 -->
			<div class="register">
				<p class="register-text1">搭建多方位营销矩阵</p>
				<p class="register-text2">利用数字技术，带来业绩增长与数据可视化决策</p>
				<div class="register-button" @click="link('register')">免费注册 →</div>
			</div>
			<div class="section">
				<div class="section-content">
					<!-- 合作企业 -->
					<div class="part1 part-partner">
						<div class="part">
							<div class="title-module">
								<div class="main-title">合作伙伴</div>
								<div class="sub-title">
									<p>为企业搭建一站式营销中心，可轻松</p>
									<p>对接多个营销终端。</p>
								</div>
							</div>
							<div class="partner-con">
								<div class="partner-item">
									<div class="partner-logo">
										<img src="https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/partner/guangyi.png">
									</div>
									<!-- <p>光蚁品牌设计</p> -->
								</div>
							</div>

							
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script type="text/javascript">

	export default {
    data(){
        return {
        	moudelName: 1,
        	muted_status: true,//视频是否静音
        }
    },
	mounted(){
		console.log('this is index')
	},
	methods: {
		 moudelf(tab) {
		 	this.moudelName = tab;
	        console.log(tab, event);
	    },
	    link(name){
            this.$router.push({name: name});
        },
        product_detail(id){
            this.$router.push({name: 'product_details',query:{id:id}});
		},
	},
  
  // router
}

</script>
<style type="text/css" scoped>
	.banner{
		width: 100%;
		/*height: 600px;*/
		color: #FFFFFF;
		display: flex;
	    justify-content: center;
	    flex-direction: column;
	    position: relative;
	}
	.banner video{
		width: 100%;
	}
	.music{
		width: 30px;
		height: 30px;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 49px;
		top: 50px;
		z-index: 999;
		color: #fff;
		border: 1px solid #fff;
		cursor: pointer;
	}
	.music-on{
		animation:turn 4s linear infinite;
	}
	@keyframes turn{
      0%{-webkit-transform:rotate(0deg);}
      25%{-webkit-transform:rotate(90deg);}
      50%{-webkit-transform:rotate(180deg);}
      75%{-webkit-transform:rotate(270deg);}
      100%{-webkit-transform:rotate(360deg);}
    }
	.music-icon{
		font-size: 28px;
	}
	.banner-con{
		width: 100%;
		height: 100%;
		display: flex;
	    justify-content: center;
	    flex-direction: column;
	    position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	.banner-con-con{
		position: absolute;
		bottom: 200px;
	}
	.banner-c-title{
		font-size: 32px;
		line-height: 32px;
		font-weight: bold;
	}
	.banner-c-text{
		width: 352px;
		font-size: 14px;
		padding-top: 10px;
	}
    .introduce-box{
    	width: 100%;
    	background-color: #F2F2F2;
    	padding: 60px 0;
    }
    .introduce{
    	width: 100%;
    	display: flex;
    	align-items: center;
    	justify-content: space-between;
    }
    .introduce-item{
    	width: 25%;
    	margin: 0 10px;
    }
    .introduce-title{
    	font-size: 14px;
    	font-weight: bold;
    	line-height: 14px;
    	color: #333;
    }
    .introduce-text{
    	font-size: 12px;
    	color: #848282;
    	padding-top: 10px;
    }
    .part{
    	width: 100%;
    	background-color: #fff;
    	border-radius: 30px;
    	overflow: hidden;
    	margin-top: 30px;
    }

    .part1-con{
    	width: 100%;
    	display: flex;
    	flex-wrap: wrap;
    	margin-left: 20px;
    	padding: 0 128px 64px 128px;

    }
    .p1-con-item{
    	width: 32%;
    	margin-right: 2%;
    	display: flex;
    	align-items: center;
    	justify-content: flex-start;
    	flex-direction: column;
    	margin-top: 60px;
    }
    .p1-con-item:nth-of-type(3n){
    	margin-right: 0;
    }
    .p1-con-icon{
    	font-size: 40px;
    	line-height: 40px;
    	color: #6f6868;
    }
    .p1-con-title{
    	font-size: 16px;
    	line-height: 16px;
    	font-weight: bold;
    	color: #666666;
    	padding-top: 14px;
    }
    .p1-con-con{
    	font-size: 12px;
    	color: #848282;
    	padding-top: 14px;
    	text-align: center;
    }
    .part-moudel,.part1{
    	width: 100%;
    	padding-left: 40px;
    	padding-right: 40px;
    }
    .part-moudel-con{
    	margin-top: 46px;
    	padding-bottom: 64px;
    }
    .p-moudel-tab{
    	width: 100%;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	font-size: 14px;
    	color: #666666;
    }
    .p-moudel-tab li{
    	cursor: pointer;
    	margin: 0 16px;
    }
    .p-moudel-tab li.active{
    	color: #169BD5;
    }
    .p-moudel-con{
    	width: 100%;
    	display: flex;
    	flex-wrap: wrap;
    	padding: 0 80px;
    }
    .p-moudel-con-item{
    	width: 220px;
    	margin-right: 70px;
    	/*border: 1px solid red;*/
    	margin-top: 64px;
    }
    .p-moudel-con-item:nth-of-type(4n){
    	margin-right: 0;
    }
    .p-moudel-con-item img{
    	position: relative;
    }
    .register{
    	width: 100%;
    	height: 528px;
    	background: url(https://aigeshou-ocean.oss-cn-shenzhen.aliyuncs.com/gorex/office/index_rebg.jpg);
    	background-repeat: no-repeat;
    	background-size: cover;
    	background-position: center;
    	background-color: rgba(0,0,0,0.8);
    	background-blend-mode: multiply;
    	margin-top: 50px;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	flex-direction: column;
    	color: #fff;
    	font-weight: 300;
    }
    .register-text1{
    	font-size: 14px;
    	line-height: 14px;
    }
    .register-text2{
    	font-size: 18px;
    	line-height: 18px;
    	padding-top: 26px;
    }
    .register-button{
    	width: 153px;
    	height: 35px;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	border-radius: 5px;
    	border: 1px solid #fff;
    	font-size: 14px;
    	margin-top: 56px;
    	cursor: pointer;
    }
    .register-button:hover{
    	color: #E79E0D;
    	border: 1px solid #E79E0D;
    	/*transform: scale(1.1);*/
    }
    .partner-con{
    	padding-bottom: 314px;
    	display: flex;
    	align-items: center;
    	flex-wrap: wrap;
    	padding: 20px 60px 60px;
    }
    .partner-item{
    	width: 20%;
    	
    	padding: 0 20px;
    	/*border: 1px solid red;*/
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	flex-direction: column;
    	margin-top: 40px;
    	font-size: 14px;
    }
    .partner-logo{
    	width: 100%;
    	height: 56px;
    	
    }
    .partner-item img{
    	width: auto;
    	height: auto;
    	max-width: 100%;
    	max-height: 100%;
    }
    .part-partner{
    	margin-top: 50px;
    }

    .online{
    	width: 100%;
    	background-color: #fff;
    	margin-top: 30px;
    	/*min-width: 1374px;
    	padding: 0 22px;
    	display: flex;
    	align-items: center;
    	justify-content: center;*/
    }
    .online-con{
    	padding: 50px 100px 20px 100px;
    	display: flex;
    	align-items: center;
    	justify-content: space-between;
    }
    .online-left{
    	flex-shrink: 0;
    	display: flex;
    	flex-direction: column;
    	margin-right: 80px;
    }
    .online-title{
    	font-size: 32px;
    	line-height: 32px;
    	color: #333333;
    	font-weight: bold;
    }
    .online-pro{
    	font-size: 14px;
    	color: #333333;
    	margin-top: 14px;
    	font-weight: bold;
    }
    .online-price{
    	font-size: 18px;
    	line-height: 18px;
    	color: #169BD5;
    	font-weight: bold;
    	margin-top: 50px;
    	font-family: "Microsoft YaHei";
    }
    .online-button{
    	width: 153px;
    	height: 35px;
    	border-radius: 5px;
    	border: 1px solid #169BD5;
    	margin-top: 16px;
    	display: flex;
    	align-items: center;
    	justify-content: center;
    	font-size: 14px;
    	color: #169BD5;
    	/*opacity: 0.6;*/
    	/*font-weight: 300;*/
    	cursor: pointer;
    }
    .online-button:hover{
    	color: #E79E0D;
    	border: 1px solid #E79E0D;
    }
    .online-right img{
    	width: 608px;
    }

	

</style>